.dropdown {
  &.show {
    .dropdown-toggle {
      &.rotate {
       .svg-icon {
        transform: rotate(180deg);
       }
      }
    }
  }
}

.dropdown-toggle {
  &.caret-off {
   &::after {
    display: none;
   }
  }
}
