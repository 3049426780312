.ng-select {
  .ng-arrow-wrapper {
    padding-right: 0;
  }

  .ng-select-container {
    padding: $form-select-padding-y $form-select-padding-x;

    border: $input-border-width solid var(--kt-input-border-color);
    @include box-shadow(var(--kt-form-select-box-shadow));

    .ng-value-container {
      padding-left: 0;

      .ng-input {
        > input {
          color: var(--kt-input-color) !important;
          font-size: 1.1rem !important;
          font-weight: 500 !important;
          line-height: 1.5 !important;
        }
      }

      .ng-placeholder {
        color: var(--kt-input-placeholder-color);
        font-size: 1.1rem;
      }

      .ng-value {
        color: var(--kt-input-color);
        font-size: 1.1rem;
        font-weight: 500;
        line-height: 1.5;
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) {
      > .ng-select-container {
        box-shadow: none;
        border-color: var(--kt-input-solid-bg-focus) !important;
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      min-height: calc(1.5rem + 1.55rem + 4px);
      padding-top: 0.575rem;
      padding-bottom: 0.575rem;

      .ng-value-container {
        padding-left: 0;
        padding-top: 0;

        .ng-input {
          padding: 0;
        }

        .ng-placeholder {
          top: auto;
          padding: 0;
        }

        .ng-value {
          display: inline-flex;
          align-items: center;

          padding: 0.1rem 0.5rem;
          margin-right: 0.5rem;
          margin-top: 0.1rem;
          margin-bottom: 0.1rem;

          background-color: var(--kt-gray-300);
          border-radius: 0.475rem;

          color: var(--kt-input-color);
          font-size: 1.1rem;

          .ng-value-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            padding: 0;

            color: var(--kt-gray-700);
            opacity: .5;
            transition: color 0.2s ease;

            &:hover {
              background-color: transparent;
              color: var(--kt-primary);
              opacity: 1;
            }

            &.left {
              border-right: 0 none;
            }
          }

          .ng-value-label {
            margin-left: 1.1rem;
            padding: 0;
          }
        }
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: auto;

      .ng-value-container {
        .ng-input {
          left: $form-select-padding-x !important;
          top: $form-select-padding-y !important;

          padding-left: 0;
          padding-right: 80px;
        }
      }
    }
  }

  &.form-select {
    padding: 0;

    border: 0 none;
    border-radius: 0;

    .ng-select-container {
      border-color: var(--kt-form-select-border-color);
      background-color: var(--kt-form-select-bg);
      box-shadow: none;
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) {
        .ng-select-container {
          border-color: var(--kt-form-select-border-color);
          background-color: var(--kt-form-select-bg);
        }
      }
    }
  }

  &.form-select-solid {
    .ng-select-container {
      box-shadow: none !important;

      background-color: var(--kt-input-solid-bg);
      border-color: var(--kt-input-solid-bg);
    }

    &.ng-select-focused {
      &:not(.ng-select-opened) {
        .ng-select-container {
          background-color: var(--kt-input-solid-bg);
          border-color: var(--kt-input-solid-bg);
        }
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        background-color: var(--kt-input-solid-bg-focus);
			  border-color: var(--kt-input-solid-bg-focus) !important;
      }
    }
  }
}

.ng-dropdown-panel {
  background-color: var(--kt-dropdown-bg);
  border: 0;
  box-shadow: var(--kt-dropdown-box-shadow);

  .ng-dropdown-footer {
    padding: 0;
    border-top: 0 none;
  }

  .ng-dropdown-panel-items {
    .ng-option {
      color: var(--kt-gray-700);

      &.ng-option-marked,
      &.ng-option-marked.ng-option-selected,
      &.ng-option-selected {
        background-color: var(--kt-component-hover-bg);
        color: var(--kt-component-hover-color);
      }
    }
  }
}
