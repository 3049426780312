.ngb-dp-header {
  padding: 0 1rem !important;
  padding-top: 0.5rem !important;
}

.ngb-dp-arrow {
  width: auto !important;
  height: auto !important;

  padding: 0 !important;
}

.ngb-dp-arrow-btn {
  width: 33px !important;
  height: 33px !important;
  margin: 0 !important;
  padding: 10px !important;

  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  &:hover {
    background: var(--bs-component-hover-bg) !important;
    border-color: transparent !important;
    color: var(--bs-component-hover-color) !important;
    outline: 0 !important;
  }
}

.ngb-dp-navigation-chevron {
  width: 10px !important;
  height: 10px !important;
  border-color: var(--bs-gray-500) !important;
  border-width: 1px 1px 0 0 !important;

  margin-left: 0 !important;
  margin-right: 0 m !important;
}

.ngb-dp-navigation-select {
  flex: 1 1 10rem;
  gap: 1rem !important;
}

ngb-datepicker-navigation-select > .form-select {
  height: 26px !important;
  padding: 0 !important;

  border: 0;
  font-size: 1rem !important;
}

.ngb-dp-content {
  padding: .5rem 1rem !important;
}

.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number {
  width: 36px !important;
  height: 36px !important;
}

.ngb-dp-weekday,
.ngb-dp-week-number {
  font-style: normal !important;
}

.ngb-dp-weekdays {
  border-bottom: none !important;
}

.ngb-dp-weekday {
  color: var(--bs-gray-800) !important;
  font-weight: 800;
}

[ngbDatepickerDayView] {
  height: 100% !important;
  width: 100% !important;
  border-radius: .475rem !important;

  color: var(--bs-gray-600);
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 36px !important;

  &:hover {
    &:not(.bg-primary) {
      background: var(--bs-component-hover-bg) !important;
      border-color: transparent !important;
      color: var(--bs-component-hover-color) !important;
      outline: 0 !important;
    }

  }
}

.ngb-dp-today > [ngbDatepickerDayView]:not(.bg-primary) {
  color: var(--bs-primary) !important;
}
