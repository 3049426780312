//
// Toastr
//

// Base
.ngx-toastr {
    background-position: 1.5rem center #{'/*rtl:calc(100% - 1.5rem) center*/'} !important;
    box-shadow: var(--kt-dropdown-box-shadow) !important;
	  border-radius: $dropdown-border-radius !important;
	  border: 0 !important;
    background-color: var(--kt-gray-100);
    color: var(--kt-gray-700);
    padding: 1.25rem 1.25rem 1.25rem 4.5rem !important;

	.toast-close-button {
   		outline: none !important;
        font-size: 0;
        width: 0.85rem;
        height: 0.85rem;
	}

	// Title
	.toast-title {
		font-size: 1.15rem;
		font-weight: $font-weight-semibold;

        & + .toast-message {
            margin-top: 0.25rem;
        }
	}

	// Message
	.toast-message {
        font-size: 1rem;
        font-weight: $font-weight-normal;
	}

    // States
    &.toast-success {
        background-color: var(--kt-success);
        color: var(--kt-success-inverse);

        .toast-close-button {
            @include svg-bg-icon(close, var(--kt-success-inverse));
        }
    }

    &.toast-info {
        background-color: var(--kt-info);
        color: var(--kt-info-inverse);

        .toast-close-button {
            @include svg-bg-icon(close, var(--kt-info-inverse));
        }
    }

    &.toast-warning {
        background-color: var(--kt-warning);
        color: var(--kt-warning-inverse);

        .toast-close-button {
            @include svg-bg-icon(close, var(--kt-warning-inverse));
        }
    }

    &.toast-error {
        background-color: var(--kt-danger);
        color: var(--kt-danger-inverse);

        .toast-close-button {
            @include svg-bg-icon(close, var(--kt-danger-inverse));
        }
    }
}


// Placements
.toast-top-center {
    top: 12px;
}

.toast-bottom-center {
    bottom: 12px;
}
