th {
  .rotate {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
  }

  .rotate-45 {
    transform: rotate(215deg);
    writing-mode: vertical-rl;
  }
}
