@include media-breakpoint-up(lg) {
  // Default
  .full-height-container {
    height: calc(100vh - 182px);
  }

  // No toolbar
  [data-kt-app-toolbar-display="false"] .full-height-container {
    height: calc(100vh - 100px);
  }

  // No header, no toolbar
  [data-kt-app-header-display="false"][data-kt-app-toolbar-display="false"] .full-height-container {
    height: calc(100vh - 30px);
  }
}
